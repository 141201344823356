import _ from 'lodash'
import { logSdkError } from '@wix/thunderbolt-commons'
import {
	LightboxContext,
	LightboxParentContext,
	namespace,
	WindowWixCodeSdkHandlers,
	WindowWixCodeSdkPageConfig,
	WindowWixCodeSdkSiteConfig,
} from '..'
import { PlatformEnvData, PlatformUtils, WixWindow, ScrollToOptions, OpenPopupOptions } from '@wix/thunderbolt-symbols'

const lightboxHandles: {
	[lightboxPageId: string]: {
		resolveOpenLightboxPromise: Function
		lightboxParentContext: LightboxParentContext
		lightboxContext: LightboxContext
	}
} = {}

export function WindowSdkFactory(
	{
		previewMode,
		isMobileFriendly,
		isPopup,
		popupNameToPageId,
		pageId,
		formFactor,
	}: WindowWixCodeSdkSiteConfig & WindowWixCodeSdkPageConfig,
	{
		getCurrentGeolocation,
		openModal,
		openLightbox,
		closeLightbox,
		scrollToHandler,
		trackEvent,
		setCurrentLanguage,
		openTpaPopup,
		getBoundingRectHandler,
	}: WindowWixCodeSdkHandlers,
	platformUtils: PlatformUtils,
	platformEnvData: PlatformEnvData
): { [namespace]: WixWindow } {
	const multilingual = platformEnvData.multilingual
	const { isSSR, browserLocale } = platformEnvData.window

	function _openLightbox(lightboxName: string, lightboxParentContext?: LightboxParentContext) {
		return new Promise((resolve, reject) => {
			if (!_.isString(lightboxName)) {
				return reject('Lightbox title is not a valid input')
			}
			if (!popupNameToPageId[lightboxName]) {
				return reject(`There is no lightbox with the title "${lightboxName}".`)
			}

			const lightboxPageId = popupNameToPageId[lightboxName]
			if (lightboxHandles[lightboxPageId]) {
				// prevent parkinson attack
				return reject('Lightbox is open')
			}

			lightboxHandles[lightboxPageId] = {
				resolveOpenLightboxPromise: resolve,
				lightboxParentContext,
				lightboxContext: null,
			}

			openLightbox(lightboxPageId, lightboxName, () => {
				resolve(lightboxHandles[lightboxPageId].lightboxContext)
				delete lightboxHandles[lightboxPageId]
			}).catch((err) => {
				delete lightboxHandles[lightboxPageId]
				reject(err)
			})
		})
	}
	function _closeLightBox(lightboxContext: LightboxContext) {
		if (!isPopup) {
			logSdkError('The current page is not a lightbox and therefore cannot be closed')
			return
		}
		if (!lightboxHandles[pageId]) {
			logSdkError('Lightbox is not open')
			return
		}
		lightboxHandles[pageId].lightboxContext = lightboxContext
		closeLightbox()
	}

	function scrollTo(x: number, y: number, options?: ScrollToOptions) {
		return new Promise((resolve, reject) => {
			// TODO Lev- extract to validation util
			let validationErrorInfo
			if (typeof x !== 'number') {
				validationErrorInfo = {
					param: 'x',
					value: x,
					expectedType: 'number',
				}
			} else if (typeof y !== 'number') {
				validationErrorInfo = {
					param: 'y',
					value: y,
					expectedType: 'number',
				}
			} else if (options && typeof options !== 'object') {
				validationErrorInfo = {
					param: 'options',
					value: options,
					expectedType: 'object',
				}
			}
			if (validationErrorInfo) {
				const { param, value, expectedType } = validationErrorInfo
				logSdkError(
					`The ${param} parameter that is passed to the scrollTo method cannot be set to the value ${value}. It must be of type ${expectedType}.`
				)
				reject({})
				return
			}

			const shouldAnimate = options?.scrollAnimation !== false
			scrollToHandler(x, y, shouldAnimate).then(resolve)
		})
	}

	const _openTpaPopup = (url: string, options: OpenPopupOptions, compId: string, persistent: boolean) =>
		openTpaPopup(
			url,
			{
				..._.defaults(options, { position: { origin: 'FIXED', placement: 'CENTER' } }),
				persistent,
			},
			compId
		)

	function getBoundingRect() {
		if (isSSR) {
			return null
		}
		return getBoundingRectHandler()
	}

	return {
		[namespace]: {
			getComponentViewportState: () =>
				Promise.resolve({
					// TODO: remove this once proGallery is in TB https://jira.wixpress.com/browse/PLAT-526
					in: true,
				}),
			multilingual: {
				siteLanguages: multilingual?.siteLanguages || [],
				isEnabled: !!multilingual,
				get currentLanguage() {
					return multilingual?.currentLanguage?.languageCode || ''
				},
				set currentLanguage(languageCode: string) {
					setCurrentLanguage(languageCode)
				},
			},
			browserLocale,
			formFactor,
			locale: 'he-il', // TODO
			referrer: null, // TODO
			viewMode: previewMode ? 'Preview' : 'Site',
			getCurrentGeolocation,
			rendering: {
				env: isSSR ? 'backend' : 'browser',
				renderCycle: 0, // TODO
				warmupData: {}, // TODO
			},
			openModal,
			openLightbox: _openLightbox,
			lightbox: {
				getContext: () => (lightboxHandles[pageId] || {}).lightboxParentContext,
				close: _closeLightBox,
			},
			scrollTo,
			trackEvent,
			openPopup: (url: string, options: OpenPopupOptions, compId: string) =>
				_openTpaPopup(url, options, compId, false),
			openPersistentPopup: (url: string, options: OpenPopupOptions, compId: string) =>
				_openTpaPopup(url, options, compId, true),
			isMobileFriendly,
			getBoundingRect,
		},
	}
}
