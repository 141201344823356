// Load `core-js` on older browsers that are missing key features
// @ts-ignore
import { FetchFn, PlatformLogger } from '@wix/thunderbolt-symbols'

if (typeof Promise === 'undefined' || typeof Set === 'undefined' || typeof Object.assign === 'undefined' || typeof Array.from === 'undefined' || typeof Symbol === 'undefined') {
	// @ts-ignore
	importScripts('https://static.parastorage.com/unpkg/core-js-bundle@3.2.1/minified.js')
}

import 'proxy-polyfill'
import _ from 'lodash'
import { expose, proxy } from 'comlink/dist/esm/comlink.js'
import { BootstrapData, ViewerAPI } from '../types'
import { startWorker } from '../core/worker'
import { createClientSiteAssetsClientFactory, getFallbackOverrideStrategy } from 'thunderbolt-site-assets-client'
import { platformFedopsMetricsReporter } from '@wix/thunderbolt-commons'

const start = async (bootstrapData: BootstrapData, updateProps: ViewerAPI['updateProps'], invokeSdkHandler: ViewerAPI['invokeSdkHandler'], updateStyles: ViewerAPI['updateStyles']) => {
	const viewerAPI: ViewerAPI = {
		updateProps,
		updateStyles,
		invokeSdkHandler: (pageId, path, ...args) => {
			if (args.length > 4) {
				console.error('sdk handlers support up to 4 arguments')
				return
			}
			const proxiedArgs = args.map((arg: any) => (_.isFunction(arg) ? proxy(arg) : arg))
			return invokeSdkHandler(pageId, path, proxiedArgs[0], proxiedArgs[1], proxiedArgs[2], proxiedArgs[3])
		}
	}

	const siteAssetsClientWorkerAdapter = (fetchFn: FetchFn, logger: PlatformLogger) => {
		return createClientSiteAssetsClientFactory({
			fetchFn,
			clientTopology: bootstrapData.siteAssetsClientInitParams.clientTopology,
			siteAssetsMetricsReporter: platformFedopsMetricsReporter(logger),
			timeout: 4000,
			manifests: bootstrapData.siteAssetsClientInitParams.manifests,
			dataFixersParams: bootstrapData.siteAssetsClientInitParams.dataFixersParams,
			requestUrl: bootstrapData.platformEnvData.location.rawUrl,
			siteScopeParams: bootstrapData.siteAssetsClientInitParams.siteScopeParams,
			moduleFetcher: {} as any,
			isStagingRequest: bootstrapData.siteAssetsClientInitParams.siteAssetsClientConfig.isStagingRequest,
			fallbackOverride: getFallbackOverrideStrategy(bootstrapData.experiments, 'clientWorker')
		})
	}

	await startWorker({
		viewerAPI,
		bootstrapData,
		componentSdksUrl: bootstrapData.componentSdksClientUrl,
		siteAssetsClientWorkerAdapter
	})
}

expose(start)
